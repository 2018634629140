import { ReactNode, useState } from 'react';

import {
  Card,
  CardContent,
  CardMedia,
  ImageList,
  Skeleton,
  Stack,
  Typography,
  styled,
  ImageListItem,
  ImageListProps,
} from '@mui/material';

import { IcDocument, IcPhoto, IcVideoRecorder } from '@/assets/images';
import { FilePreviewModal } from '@/components';
import { useFetchSasToken } from '@/hooks';
import { IFile } from '@/models';
import { useFileStore } from '@/store';
import { colors } from '@/theme/variables';
import { getFileType } from '@/utils';

interface FileGridProps extends Omit<ImageListProps, 'children'> {
  files: IFile[];
}

const FileGridRoot = styled(ImageList)(() => ({
  '& img': {
    width: '100%',
    objectFit: 'contain',
    borderRadius: 8,
    cursor: 'pointer',
  },
  '& .file-grid-card': {
    padding: '10px',
    boxShadow: 'none',
    backgroundColor: colors.grey[10],
    cursor: 'pointer',
    width: '100%',
  },
  '& .file-grid-content': {
    padding: 0,
    paddingBottom: '10px',
  },
  '& .file-grid-info': {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    minWidth: 0,
    height: '24px',
  },
  '& .file-grid-typography': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: '15px',
    fontWeight: 500,
  },
  '& .file-grid-preview': {
    color: colors.grey[100],
    width: '100%',
    fontWeight: 500,
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const FileGrid = ({ files, ...rest }: FileGridProps) => {
  const { fetching } = useFileStore();
  const [selectedFile, setSelectedFile] = useState<IFile | undefined>(
    undefined,
  );

  const sasToken = useFetchSasToken(files, fetching);

  const handleCardClick = (file: IFile) => {
    setSelectedFile(file);
  };

  const renderFileItem = (file: IFile) => {
    if (!sasToken || !file.url) return <Skeleton key={file.name} />;

    const fileType = getFileType(file.mimeType);

    const icons = {
      video: <IcVideoRecorder width={72} />,
      image: <IcPhoto width={34} />,
      document: <IcDocument width={24} />,
    };

    const previewContent: Record<string, ReactNode> = {
      video: (
        <CardMedia
          component="video"
          className="file-grid-preview"
          style={{ maxHeight: '600px' }}
          src={`${file.url}?${sasToken}`}
          controls
        />
      ),
      image: (
        <ImageListItem>
          <img src={`${file.url}?${sasToken}`} alt={file.name} loading="lazy" />
        </ImageListItem>
      ),
      document: (
        <Stack
          className="file-grid-preview"
          sx={{
            height: '176px',
            width: '198px',
            backgroundColor: 'white',
          }}
        >
          <IcDocument />
        </Stack>
      ),
    };

    return (
      <Card className="file-grid-card" onClick={() => handleCardClick(file)}>
        <CardContent className="file-grid-content">
          <Stack direction="row" className="file-grid-info">
            {icons[fileType]}
            <Typography
              variant="body1"
              color="textSecondary"
              className="file-grid-typography"
            >
              {file.name}
            </Typography>
          </Stack>
        </CardContent>
        {previewContent[fileType]}
      </Card>
    );
  };

  return (
    <FileGridRoot cols={4} gap={16} {...rest}>
      {files?.map(renderFileItem)}
      <FilePreviewModal
        open={Boolean(selectedFile)}
        file={selectedFile}
        onClose={() => setSelectedFile(undefined)}
      />
    </FileGridRoot>
  );
};
