import { AxiosResponse } from 'axios';

import { ApiVersion } from '@/config';
import {
  IComment,
  ITag,
  ITask,
  ITaskCreate,
  ITaskStatus,
  ITaskType,
  ITaskViewResponse,
} from '@/models';

import { http } from './http';
import { IGetParams, IListResponse } from './types';

export const TaskApi = {
  getTasks: async (
    params: IGetParams,
  ): Promise<AxiosResponse<IListResponse<ITask>>> => {
    return http.get(`api/${ApiVersion.V1}/tasks/`, { params });
  },
  getTask: async (id: string): Promise<AxiosResponse<ITaskViewResponse>> => {
    return http.get(`api/${ApiVersion.V1}/tasks/${id}`);
  },
  createTask: async (data: ITaskCreate): Promise<AxiosResponse<ITask>> => {
    return http.post(`api/${ApiVersion.V1}/tasks/`, data);
  },
  updateTask: async (
    data: Partial<ITaskCreate>,
  ): Promise<AxiosResponse<ITask>> => {
    return http.put(`api/${ApiVersion.V1}/tasks/${data._id}`, data);
  },
  deleteTask: async (id: string): Promise<AxiosResponse<ITask>> => {
    return http.delete(`api/${ApiVersion.V1}/tasks/${id}`);
  },
  // task comments
  getTaskComments: async (
    taskId: string,
  ): Promise<AxiosResponse<IComment[]>> => {
    return http.get(`api/${ApiVersion.V1}/tasks/${taskId}/comments`);
  },
  createTaskComment: async (
    taskId: string,
    description: string,
  ): Promise<AxiosResponse<IComment>> => {
    return http.post(`api/${ApiVersion.V1}/tasks/${taskId}/comments`, {
      description,
    });
  },
  getTaskStatuses: async (): Promise<AxiosResponse<ITaskStatus[]>> => {
    return http.get(`api/${ApiVersion.V1}/tasks/statuses`);
  },
  getTaskTags: async (): Promise<AxiosResponse<ITag[]>> => {
    return http.get(`api/${ApiVersion.V1}/tasks/tags`);
  },
  getTaskTypes: async (): Promise<AxiosResponse<ITaskType[]>> => {
    return http.get(`api/${ApiVersion.V1}/tasks/types`);
  },
};
