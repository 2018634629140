import { colors } from '../variables';

export default {
  MuiButton: {
    styleOverrides: {
      root: ({ ownerState, theme }: any) => ({
        borderRadius: 6,
        textTransform: 'none' as const,
        boxShadow: 'none',
        ...(ownerState.variant === 'contained' && {
          boxShadow: 'none',
          padding: '8px 20px',
        }),
        '&.Mui-disabled': {
          borderWidth: 0,
        },
        ...(ownerState.variant === 'contained' &&
          ownerState.color === 'inherit' && {
            border: `1px solid ${colors.grey[20]}`,
            backgroundColor: colors.white,
            color: colors.grey[100],
            boxShadow: 'none',

            ...(theme.palette.mode !== 'light' && {
              backgroundColor: colors.grey[80],
              borderColor: colors.grey[70],
              color: colors.grey[20],
            }),

            '&:hover': {
              boxShadow: 'none',

              ...(theme.palette.mode !== 'light' && {
                backgroundColor: colors.grey[80],
                opacity: 0.7,
              }),
            },
          }),
        ...(ownerState.variant === 'contained' &&
          ownerState.color === 'primary' && {
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            },
          }),
        ...(ownerState.variant === 'contained' &&
          ownerState.color === 'error' && {
            border: `1px solid ${colors.error.main}`,
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: colors.error.dark,
              boxShadow: 'none',
            },
          }),
        ...(ownerState.variant === 'outlined' &&
          ownerState.color === 'inherit' && {
            borderColor: colors.grey[20],
            '&:hover': {
              boxShadow: 'none',
            },
          }),
      }),
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: ({ theme }: any) => ({
        color: colors.grey[80],

        ...(theme.palette.mode !== 'light' && {
          color: colors.grey[20],
        }),
      }),
    },
  },
};
