import { Button, Modal, ModalProps, styled, Typography } from '@mui/material';

import { IcArrowLeft, IcClose } from '@/assets/images';

import { CloseButton, ModalContent } from './styles';

interface SideModalProps extends Omit<ModalProps, 'onClose'> {
  onClose: () => void;
  width?: string;
  closingType?: 'close' | 'hide';
}

const HideButton = styled(Button)`
  writing-mode: tb-rl;
  transform: rotate(180deg);
  height: 100%;
  font-size: 15px;
  minwidth: 46px;
  text-align: top;
  background-color: #e7eaec;
  color: #0e3145;
  align-items: baseline;
  padding: 12px;
  border-radius: 0 8px 8px 0;
  &:hover {
    background-color: #d0d0d0;
  }
`;

export const SideModal = ({
  onClose,
  children,
  width,
  closingType = 'close',
  ...rest
}: SideModalProps) => {
  return (
    <Modal {...rest}>
      <div
        style={{ display: 'flex', height: '100vh', justifyContent: 'flex-end' }}
      >
        {closingType === 'hide' && (
          <div style={{ width: '46px' }}>
            <HideButton variant="contained" onClick={onClose}>
              <Typography fontWeight={500} fontSize={15}>
                Hide
              </Typography>
              <IcArrowLeft style={{ marginTop: 10 }} />
            </HideButton>
          </div>
        )}
        <ModalContent width={width}>
          {closingType === 'close' && (
            <CloseButton onClick={onClose}>
              <IcClose width={40} height={40} />
            </CloseButton>
          )}
          {children}
        </ModalContent>
      </div>
    </Modal>
  );
};
