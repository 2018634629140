export enum LocalStorageItems {
  AccessToken = 'access-token',
  RefreshToken = 'refresh-token',
  CurrentAgencyId = 'current-agency-id',
}

export enum LocalStoragePrefix {
  KaseTableSetting = 'kase-table-setting',
  MasterIndexTableSetting = 'master-index-table-setting',
  UnitFilters = 'unit-filters',
}
