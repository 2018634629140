import { useCallback, useEffect, useState } from 'react';

import { FilterValues } from '@/components';
import { AssignedUnitShiftItem } from '@/contexts';
import { IAssignedUnitShift, ICFS, IUnit, IUnitShift } from '@/models';

type IUnitItemList = (IUnit | IUnitShift | AssignedUnitShiftItem)[];

interface IUnitListFilterProps {
  filterValues: FilterValues;
  cfs: ICFS | null;
  unitData: IUnitItemList;
  searchText?: string;
  searchResources?: boolean;
}

export const useUnitListFilter = ({
  filterValues,
  cfs,
  unitData,
  searchText,
  searchResources,
}: IUnitListFilterProps) => {
  const [filteredUnits, setFilteredUnits] = useState<IUnitItemList>([]);

  useEffect(() => {
    const { agencies, beat, agencyType, unitTypes } = filterValues;
    let filteredUnitData = unitData.slice();

    if (searchText) {
      filteredUnitData = filteredUnitData.filter((item) => {
        const caseInsensitiveSearch = searchText.toLowerCase();
        const caseInsensitiveName =
          (item as IUnit)?.name ||
          (item as IUnitShift)?.unit?.name ||
          (item as IAssignedUnitShift)?.unitShift?.unit?.name;

        return (
          caseInsensitiveName.toLowerCase().includes(caseInsensitiveSearch) ||
          (searchResources &&
            searchByResources(item as IUnitShift, caseInsensitiveSearch))
        );
      });
    }
    if (agencies && agencies.length) {
      filteredUnitData = filteredUnitData.filter(
        (item) =>
          agencies.indexOf(
            'unitShift' in item
              ? item.unitShift.unit.agency
              : 'unit' in item
              ? item.unit.agency
              : item.agency,
          ) > -1,
      );
    }
    if (beat) {
      filteredUnitData = filteredUnitData.filter((item) =>
        'unitShift' in item
          ? item.unitShift.unit.polygons?.includes(beat)
          : 'unit' in item
          ? item.unit.polygons?.includes(beat)
          : item.polygons?.includes(beat),
      );
    }
    if (agencyType) {
      filteredUnitData = filteredUnitData.filter(
        (item) =>
          agencyType ===
          ('unitShift' in item
            ? item.unitShift.unit.type.agencyType
            : 'unit' in item
            ? item.unit.type.agencyType
            : item.type.agencyType),
      );
    }
    if (unitTypes && unitTypes.length) {
      filteredUnitData = filteredUnitData.filter((item) => {
        return unitTypes?.includes(
          'unitShift' in item && item.unitShift.unit.type._id
            ? item.unitShift.unit.type._id
            : 'unit' in item && item.unit.type._id
            ? item.unit.type._id
            : 'type' in item && item.type._id
            ? item.type._id
            : '',
        );
      });
    }

    // Sort filtered data by distance from unit shift to CFS
    if (cfs?.unitShiftDistances.length && filteredUnitData.length) {
      const unitShiftDistances = cfs?.unitShiftDistances;
      if ((filteredUnitData[0] as IUnitShift)?.location) {
        (filteredUnitData as IUnitShift[]).sort((a, b) => {
          const aDistance = unitShiftDistances.find(
            ({ unitShift }) => a._id === unitShift,
          )?.distance;
          const bDistance = unitShiftDistances.find(
            ({ unitShift }) => b._id === unitShift,
          )?.distance;
          if (aDistance === undefined) return 1;
          if (bDistance === undefined) return -1;
          return aDistance - bDistance;
        });
      } else if (
        (filteredUnitData[0] as AssignedUnitShiftItem)?.unitShift?.location
      ) {
        (filteredUnitData as AssignedUnitShiftItem[]).sort((a, b) => {
          const aDistance = unitShiftDistances.find(
            ({ unitShift }) => a?.unitShift?._id === unitShift,
          )?.distance;
          const bDistance = unitShiftDistances.find(
            ({ unitShift }) => b?.unitShift?._id === unitShift,
          )?.distance;
          if (aDistance === undefined) return 1;
          if (bDistance === undefined) return -1;
          return aDistance - bDistance;
        });
      }
    }

    setFilteredUnits(filteredUnitData);
  }, [filterValues, JSON.stringify(unitData), cfs, searchText]);

  const searchByResources = useCallback(
    (unitShift?: IUnitShift, _searchText?: string) => {
      if (!unitShift) return false;
      if (!_searchText) return true;
      const { users, vehicles, equipments } = unitShift;

      const hasMatchedUser = users?.find(({ firstName, lastName }) =>
        `${firstName?.toLowerCase()} ${lastName?.toLowerCase()}`.includes(
          _searchText,
        ),
      );
      if (hasMatchedUser) return true;

      const hasMatchedVehicle = vehicles?.find(({ name }) =>
        name?.toLowerCase().includes(_searchText),
      );
      if (hasMatchedVehicle) return true;

      return !!equipments?.find(({ name }) =>
        name?.toLowerCase().includes(_searchText),
      );
    },
    [],
  );

  return filteredUnits;
};
