import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ModalProps } from '@mui/material';

import { UnitShiftProvider } from '@/contexts';
import { useUnitShiftContext } from '@/hooks';
import ActiveCFSDetail from '@/pages/CFS/Edit';

import { SideModal } from '../SideModal';

interface UnitShiftListModalProps
  extends Omit<ModalProps, 'children' | 'onClose'> {
  cfsId?: string;
  onClose?: () => void;
  fetchActiveCFSList: () => void;
}

export const UnitShiftListModalComp = ({
  fetchActiveCFSList,
  onClose,
  ...rest
}: UnitShiftListModalProps) => {
  const { fetchAssignedUnitShifts } = useUnitShiftContext();
  const navigate = useNavigate();
  useEffect(() => {
    fetchAssignedUnitShifts();
  }, []);

  if (!onClose) {
    onClose = () => {
      navigate('/cfs-active');
    };
  }

  const urlParams = useParams();
  const cfsId = rest?.cfsId || String(urlParams.cfsId);

  if (!cfsId) {
    return null;
  }

  return (
    <SideModal {...rest} onClose={onClose} width="90%" closingType="hide">
      <ActiveCFSDetail cfsId={cfsId} isQuickView={true} />
    </SideModal>
  );
};

export const UnitShiftListModal = (props: UnitShiftListModalProps) => (
  <UnitShiftProvider>
    <UnitShiftListModalComp {...props} />
  </UnitShiftProvider>
);
