import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { MapEvent, MapProps } from '@vis.gl/react-google-maps';

import { MapLayerApi } from '@/api';
import fireHydrantImage from '@/assets/images/pngs/fire_hydrant.png';
import { useToastStore } from '@/store';

import { GoogleMap } from './GoogleMap';
import { IMarker } from './MapMarker';

interface GoogleMapProps extends Omit<MapProps, 'center'>, PropsWithChildren {
  center?: google.maps.LatLngLiteral;
  markers?: IMarker[];
  onHandleMapTypeIdChange?: (mapTypeId?: string) => void;
  showContextMenu?: boolean;
  showStreetViewControl?: boolean;
}

export const FireHydrantMap: FC<GoogleMapProps> = (props) => {
  const {
    markers = [],
    onHandleMapTypeIdChange,
    showContextMenu,
    showStreetViewControl,
    ...rest
  } = props;
  const [queryParams] = useSearchParams();
  const [hydrantPointMarkers, setHydrantPointMarkers] = useState<IMarker[]>([]);
  const { updateToast } = useToastStore();

  const mapTypeId = queryParams.get('mapTypeId') || 'roadmap';

  useEffect(() => {
    fetchFireHydrantPoints();
  }, []);

  const fetchFireHydrantPoints = async () => {
    try {
      const params = {
        page: 1,
        limit: 1000,
        filter: JSON.stringify({ type: 'FIRE_HYDRANT' }),
      };
      const res = await MapLayerApi.getPointLayers(params);
      const hydrantMarkers = res.data.results.map(({ _id, name, point }) => ({
        key: String(_id),
        position: { lng: point.coordinates[0], lat: point.coordinates[1] },
        icon: fireHydrantImage,
        info: name,
      }));
      setHydrantPointMarkers(hydrantMarkers);
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    }
  };

  const handleMapTypeIdChange = (event: MapEvent) => {
    if (onHandleMapTypeIdChange) {
      onHandleMapTypeIdChange(event.map.getMapTypeId());
    }
  };

  return (
    <GoogleMap
      markers={hydrantPointMarkers.concat(markers)}
      onMapTypeIdChanged={handleMapTypeIdChange}
      mapTypeId={mapTypeId}
      showContextMenu={showContextMenu}
      showStreetViewControl={showStreetViewControl}
      {...rest}
    />
  );
};
