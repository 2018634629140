import { Stack, Typography } from '@mui/material';
import moment from 'moment';

import { IcClock, IcPencil } from '@/assets/images';
import { DateTimePicker } from '@/components';
import { DATE_TIME_FORMAT } from '@/config';
import { colors } from '@/theme/variables';

interface CFSReporterTimeItemProps {
  reporterTime?: string;
  onAccept?: (newDateTime: string) => void;
}

export const CFSReporterTimeItem = ({
  reporterTime,
  onAccept,
}: CFSReporterTimeItemProps) => {
  return (
    <Stack direction="row" alignItems="center" columnGap={1}>
      <IcClock color={colors.grey[80]} height={20} />
      <Typography color={colors.grey[80]} variant="body2">
        {moment(reporterTime).format(DATE_TIME_FORMAT)}
      </Typography>
      {!!onAccept && (
        <DateTimePicker
          pickerIcon={<IcPencil />}
          onAccept={(v) => onAccept(moment(v).toISOString())}
          hideInput
        />
      )}
    </Stack>
  );
};
