import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import { OpenInFull } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import * as Ably from 'ably';
import { useChannel } from 'ably/react';

import { CfsApi } from '@/api';
import { AdditionalInfoForm, FormCard } from '@/components';
import { useCFSContext } from '@/hooks';
import { useToastStore, useUserStore } from '@/store';
import { openNewWindow } from '@/utils';

import { FormItemRoot, LiveInformationRoot } from '../styles';

interface LiveInformationProps {
  isNewWindow?: boolean;
  cfsId?: string;
}

export const LiveInformation = ({
  isNewWindow,
  ...rest
}: LiveInformationProps) => {
  const urlParams = useParams();
  const cfsId = rest.cfsId || String(urlParams.cfsId);

  const { updateToast } = useToastStore();
  const { user, account } = useUserStore();

  const { cfs, updateCFS } = useCFSContext();
  const additionalInfo = cfs?.additionalInfo || [];

  const { channel } = useChannel(
    `account:${account?._id}:cfs:${cfsId}`,
    'additionalInfo',
    (message: Ably.Types.Message) => {
      if (cfs) {
        updateCFS({
          ...cfs,
          additionalInfo: [
            ...cfs.additionalInfo,
            {
              ...message.data,
              isNew: !open && message.data?.createdBy?._id !== user?._id,
            },
          ],
        });
      }
    },
  );

  const onSubmit = (newDescription: string) => {
    CfsApi.addAdditionalInfo(cfsId, { description: newDescription })
      .then(() => {
        if (user) {
          const newAdditionalInfo = {
            createdBy: {
              _id: user._id,
              firstName: user.firstName,
              lastName: user.lastName,
              fullName: user.fullName,
            },
            description: newDescription,
            createdAt: new Date().toISOString(),
          };

          if (cfs)
            updateCFS({
              ...cfs,
              additionalInfo: [...additionalInfo, newAdditionalInfo],
            });

          channel?.publish('additionalInfo', newAdditionalInfo);
        }
      })
      .catch((err: any) => {
        updateToast({ open: true, message: err.message });
      });
  };

  return (
    <LiveInformationRoot>
      {isNewWindow && (
        <HelmetProvider>
          <Helmet>
            <title>{`${cfs?.number} - Live information`}</title>
          </Helmet>
        </HelmetProvider>
      )}
      <FormCard
        title={
          (isNewWindow && cfs?.number ? `${cfs.number} - ` : '') +
          'Live information'
        }
        classes={{
          cardContainer: 'live-info-card-container',
          cardContent: 'live-info-card-content',
        }}
      >
        <FormItemRoot>
          {!isNewWindow && (
            <IconButton
              sx={{ position: 'absolute', right: 10, top: 8 }}
              onClick={() => {
                openNewWindow(
                  `/cfs/${cfsId}/live-info`,
                  `${cfsId} - Live information`,
                  500,
                  500,
                );
              }}
            >
              <OpenInFull fontSize="small" />
            </IconButton>
          )}
          <AdditionalInfoForm
            additionalInfoList={additionalInfo}
            classes={
              isNewWindow ? { additionalInfoList: 'new-window' } : undefined
            }
            onSubmit={onSubmit}
          />
        </FormItemRoot>
      </FormCard>
    </LiveInformationRoot>
  );
};
