import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';

import { Grid, Stack } from '@mui/material';

import { Page, UnitShiftList } from '@/components';
import { cfsTabs } from '@/constants';
import { CFSProvider, UnitShiftProvider } from '@/contexts';
import { useCFSContext, useUnitShiftContext } from '@/hooks';
import { BottomTabBar, TabLayout } from '@/layouts/TabLayout';
import { ICFSForm } from '@/models';
import { parsePhoneNumber } from '@/utils';

import { CFSForm } from './components/CFSForm';
import CFSHeader from './components/CFSHeader';
import { CFSMap } from './components/CFSMap';
import { LiveInformation } from './components/LiveInformation';
import { cfsDefaultValues } from './data';
import { CFSEditRoot } from './styles';

const CFSEdit = (props: { cfsId?: string; isQuickView?: boolean }) => {
  const urlParams = useParams();
  const cfsId = props?.cfsId || String(urlParams.cfsId);
  const { cfs } = useCFSContext();
  const { fetchAssignedUnitShifts } = useUnitShiftContext();

  const { pathname } = useLocation();
  const paths = pathname.split('/');

  const formMethods = useForm<ICFSForm>({
    defaultValues: cfsDefaultValues,
    mode: 'onChange',
  });

  useEffect(() => {
    fetchAssignedUnitShifts();
  }, []);

  useEffect(() => {
    if (cfs?._id) {
      const data: ICFSForm = {
        number: cfs.number,
        severity: cfs.severity,
        shortDescription: cfs.shortDescription,
        incidentCodes: cfs.incidentCodes.map((code) => String(code._id)),
        addressInfo: {
          ...cfsDefaultValues.addressInfo,
          ...cfs.addressInfo,
          instructions: cfs.addressInfo?.instructions || '',
        },
        reporter: {
          ...cfs.reporter,
          contactPhoneNumber: parsePhoneNumber(cfs.reporter.contactPhoneNumber),
        },
      };
      formMethods.reset(data);
    }
  }, [cfs?._id]);

  return (
    <Page
      title={cfs ? `${cfs?.number} detail page` : 'Loading ...'}
      style={{ height: '100vh' }}
    >
      <CFSEditRoot>
        <FormProvider {...formMethods}>
          <Grid columnSpacing={2} container>
            <Grid item xs={12} lg={8}>
              <Stack justifyContent="space-between" rowGap={2.5}>
                <CFSHeader cfsId={cfsId} />

                <Stack className="active-cfs-content">
                  <Grid spacing={2} container sx={{ flex: 1 }}>
                    <Grid item xs={12} lg={6}>
                      <CFSForm cfsId={cfsId} />
                    </Grid>

                    <Grid item xs={12} lg={6} className="active-cfs-widgets">
                      <Stack spacing={2} sx={{ height: '100%' }}>
                        <CFSMap cfsId={cfsId} />
                        <LiveInformation cfsId={cfsId} />
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>
              </Stack>
              <TabLayout
                tabLinks={cfsTabs}
                basePath={`/${paths[1]}/${paths[2]}`}
                style={{ width: 'calc(100% - 40px)', position: 'absolute' }}
              >
                <BottomTabBar
                  additionalInfo={[
                    {
                      showWarning: cfs?.hasUnsetInvolvementName,
                      count: cfs?.names,
                    },
                    { count: cfs?.vehicles },
                  ]}
                  isQuickView={props.isQuickView}
                  cfsId={cfsId} // Pass cfsId prop
                />
              </TabLayout>
            </Grid>

            <Grid item xs={12} lg={4} className="mapbox-unit-list">
              <Stack className="unit-list">
                <UnitShiftList cfsId={cfsId} />
              </Stack>
            </Grid>
          </Grid>
        </FormProvider>
      </CFSEditRoot>
    </Page>
  );
};

const ActiveCFSDetail = (props: { cfsId?: string; isQuickView?: boolean }) => {
  const urlParams = useParams();
  const cfsId = props?.cfsId || String(urlParams.cfsId);

  if (!cfsId) return null;

  return (
    <CFSProvider cfsId={cfsId}>
      <UnitShiftProvider>
        <CFSEdit cfsId={cfsId} isQuickView={props.isQuickView} />
      </UnitShiftProvider>
    </CFSProvider>
  );
};

export default ActiveCFSDetail;
