import { Tabs as MuiTabs, styled } from '@mui/material';

import { colors } from '@/theme/variables';

export const Tabs = styled(MuiTabs)(({ theme }) => ({
  marginBottom: -1,

  '& .MuiTab-root': {
    minHeight: 0,
    minWidth: 0,
    padding: 0,
    margin: '15px 10px',
    textTransform: 'capitalize',
    color: colors.grey[60],
    fontWeight: 500,
    lineHeight: '1.25rem',

    ...(theme.palette.mode !== 'light' && {
      color: colors.grey[20],
    }),

    '&.Mui-selected': {
      color: colors.grey[100],
    },

    '&:first-of-type': {
      marginLeft: 0,
    },
  },
  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.error.main,
  },
}));
