import { useState } from 'react';

import { FormHelperText, InputLabel, Stack, Typography } from '@mui/material';

import { AddressApi, MileMarkerApi } from '@/api';
import { IAddress, IAddressForm, IMileMarker } from '@/models';
import { getAddressLocation } from '@/services';
import { googlePlaceService } from '@/services/service.google';
import { useToastStore } from '@/store';

import { AddressSearch, AddressOptionType } from './AddressSearch';
import { BaseFieldProps } from '../../FormElements';

interface AddressItemProps extends BaseFieldProps {
  name?: string;
  value?: AddressOptionType;
  locationRestriction?: google.maps.LatLngBoundsLiteral;
  updateCurrentAddress: (newAddress: IAddressForm | null) => void;
}

export const AddressItem = (props: AddressItemProps) => {
  const { label, value, error, locationRestriction, updateCurrentAddress } =
    props;
  const { updateToast } = useToastStore();
  const [options, setOptions] = useState<AddressOptionType[]>([]);
  const [mileMarkers, setMileMarkers] = useState<IMileMarker[]>([]);

  const retrieveFullAddress = async (newValue: AddressOptionType) => {
    try {
      let addressDetails: IAddressForm | null = null;
      if (newValue.placeId === 'mileMarker') {
        const currentMileMarker =
          mileMarkers.find(
            (marker) => marker.addressLabel === newValue.mainText,
          ) || null;
        if (currentMileMarker) {
          addressDetails = {
            pointType: 5,
            addressNumber: currentMileMarker.addressNumber,
            addressLabel: currentMileMarker.addressLabel,
            completeStreetName: currentMileMarker.completeStreetName,
            state: currentMileMarker.state,
            zipName: currentMileMarker.zipName,
            zipcode: currentMileMarker.zipCode,
            point: {
              type: 'Point',
              coordinates: [
                currentMileMarker.longitude,
                currentMileMarker.latitude,
              ],
            },
          };
        }
      } else {
        addressDetails = await googlePlaceService.getPlaceDetails(
          String(newValue.placeId),
        );
      }
      if (addressDetails) {
        addNewAddress(addressDetails);
        updateCurrentAddress(addressDetails);
      }
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    }
  };

  const addNewAddress = async (newAddress: IAddressForm) => {
    try {
      const res = await AddressApi.createAddress(newAddress);
      updateCurrentAddress(res.data);
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    }
  };

  const onSearch = async (inputValue: string) => {
    try {
      if (inputValue?.startsWith('&HW ')) {
        const res = await MileMarkerApi.list({
          search: inputValue.replace('&HW ', ''),
        });
        const newOptions = res.data.map((marker) => ({
          mainText: marker.addressLabel,
          secondaryText: `${marker.completeStreetName}, ${marker.state}, ${marker.zipCode}`,
          placeId: 'mileMarker',
        }));
        setMileMarkers([...res.data]);
        setOptions(newOptions);
      } else {
        const res = await googlePlaceService.getPlacePredictions(
          inputValue,
          locationRestriction,
        );
        const newOptions = res.map(({ structured_formatting, place_id }) => ({
          mainText: structured_formatting.main_text,
          secondaryText: structured_formatting.secondary_text,
          placeId: place_id,
        }));
        setOptions(newOptions);
      }
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    }
  };

  const handleChange = (newValue: string | AddressOptionType | null) => {
    if (!!newValue && typeof newValue !== 'string') {
      retrieveFullAddress(newValue);
    } else {
      updateCurrentAddress(null);
    }
  };

  const handleInputChange = (newValue: string) => {
    if (newValue.length > 0) {
      onSearch(newValue);
    } else {
      setMileMarkers([]);
      setOptions([]);
    }
  };

  const getInputText = (option: AddressOptionType | string) => {
    if (typeof option === 'string') {
      return option;
    }

    const { mainText, secondaryText } = option || {};
    if (mainText && secondaryText) {
      return `${mainText} ${secondaryText}`;
    }

    return getAddressLocation(option as IAddress);
  };

  const checkOptionEqualToValue = (
    option: string | AddressOptionType,
    newValue: string | AddressOptionType,
  ) => {
    if (typeof option === 'string' || typeof newValue === 'string') {
      return option === newValue;
    }
    return `${option.mainText}` === `${newValue?.addressLabel}`;
  };

  const renderOption = (option: AddressOptionType) => {
    const { mainText, secondaryText } = option;
    return (
      <Stack sx={{ ml: 1 }}>
        <Typography variant="subtitle2">{mainText}</Typography>
        <Typography variant="body2">{secondaryText}</Typography>
      </Stack>
    );
  };

  return (
    <Stack>
      {!!label && <InputLabel>{label}</InputLabel>}
      <AddressSearch
        filterOptions={(filterOptions) => filterOptions}
        options={options}
        value={value}
        onChange={(_, selected) => {
          handleChange(selected);
        }}
        handleInputChange={handleInputChange}
        getOptionLabel={getInputText}
        renderOption={renderOption}
        isOptionEqualToValue={checkOptionEqualToValue}
      />
      <FormHelperText error>{error}</FormHelperText>
    </Stack>
  );
};
