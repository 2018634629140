import { IcBox, IcRoadAssistance, IcUsers } from '@/assets/images';
import { AgencyType, AssignedUnitShiftStatus } from '@/models';

import { ActionButtonItem } from '../../Buttons';

export const UnitResourceFormFields: Record<string, any> = {
  VEHICLE: {
    name: 'vehicles',
    type: 'autocomplete',
    label: 'Vehicles',
    errorLabel: 'vehicle',
    multiple: true,
    limitTags: 3,
    rules: {
      required: 'This field is required.',
    },
  },
  PEOPLE: {
    name: 'users',
    type: 'autocomplete',
    label: 'Employees',
    errorLabel: 'employee',
    multiple: true,
    limitTags: 3,
    rules: {
      required: 'This field is required.',
    },
  },
  EQUIPMENT: {
    name: 'equipments',
    type: 'autocomplete',
    label: 'Equipment',
    errorLabel: 'equipment resource',
    multiple: true,
    limitTags: 3,
    rules: {
      required: 'This field is required.',
    },
  },
  POLYGON_LAYER: {
    name: 'polygons',
    type: 'autocomplete',
    label: 'Polygons',
    errorLabel: 'polygon resource',
    multiple: true,
    limitTags: 3,
    rules: {
      required: 'This field is required.',
    },
  },
};

export const initFilterValues = {
  agencies: [],
  beat: '',
  agencyType: null,
  unitTypes: [],
  name: '',
};

export const agencyTypeItems = [
  {
    label: 'EMS',
    value: AgencyType.EMS,
  },
  {
    label: 'FIRE',
    value: AgencyType.FIRE,
  },
  {
    label: 'POLICE',
    value: AgencyType.POLICE,
  },
];

export const availableUnitActions = [
  {
    label: 'Assign',
    value: 'assign',
  },
  {
    label: 'Set off-duty',
    value: 'set-off-duty',
  },
];

export const availableHQUnitActions = [
  {
    label: 'Assign',
    value: 'assign',
  },
  {
    label: 'Assign to HQ',
    value: 'assignToHQ',
  },
  {
    label: 'Set off-duty',
    value: 'set-off-duty',
  },
];

export const unitShiftStatusItems: ActionButtonItem[] = [
  {
    label: 'Assigned',
    value: AssignedUnitShiftStatus.ASSIGNED,
  },
  {
    label: 'En Route',
    value: AssignedUnitShiftStatus.EN_ROUTE,
  },
  {
    label: 'On scene',
    value: AssignedUnitShiftStatus.ON_SCENE,
  },
  {
    label: 'In progress',
    value: AssignedUnitShiftStatus.PROGRESS,
  },
];

export const unitShiftActionItems = [
  {
    label: 'Complete',
    value: AssignedUnitShiftStatus.COMPLETED,
  },
  {
    label: 'Cancel',
    value: AssignedUnitShiftStatus.CANCELED,
  },
];

export const unitShiftHQActionItems = [
  {
    label: 'Assign to call',
    value: 'assignToCall',
  },
  {
    label: 'Complete',
    value: AssignedUnitShiftStatus.COMPLETED,
  },
  {
    label: 'Cancel',
    value: AssignedUnitShiftStatus.CANCELED,
  },
];

export const unitShiftCallActionItems = [
  {
    label: 'Assign to HQ',
    value: 'assignToHQ',
  },
  {
    label: 'Complete',
    value: AssignedUnitShiftStatus.COMPLETED,
  },
  {
    label: 'Cancel',
    value: AssignedUnitShiftStatus.CANCELED,
  },
];

export const unitShiftReassignActionItems = [
  {
    label: 'Complete and reassign',
    value: AssignedUnitShiftStatus.COMPLETED,
  },
  {
    label: 'Cancel and reassign',
    value: AssignedUnitShiftStatus.CANCELED,
  },
  {
    label: 'Queue',
    value: AssignedUnitShiftStatus.QUEUED,
  },
];

export const unitShiftViewItems = [
  {
    name: 'users',
    icon: IcUsers,
    items: [],
  },
  {
    name: 'vehicles',
    icon: IcRoadAssistance,
    items: [],
  },
  {
    name: 'equipments',
    icon: IcBox,
    items: [],
  },
];

export const unitFilterMenuTabs = [
  {
    value: 'currentFilter',
    label: 'Filter',
  },
  {
    value: 'savedFilters',
    label: 'Saved filters',
  },
];
