import { useEffect, useState } from 'react';

import { IFile } from '@/models';
import { getSasTokenFromFileUrl } from '@/services';
import { useToastStore } from '@/store';

export const useFetchSasToken = (
  files?: (IFile | undefined)[] | null,
  fetching?: boolean,
) => {
  const { updateToast } = useToastStore();
  const [sasToken, setSasToken] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!fetching && files?.length) {
      fetchSasToken();
    }
  }, [files, fetching]);

  const fetchSasToken = async () => {
    try {
      const fileWithUrl = files?.find((file) => file && !!file.url);
      if (fileWithUrl) {
        const fileSasToken = await getSasTokenFromFileUrl(fileWithUrl.url);
        setSasToken(fileSasToken);
      }
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    }
  };

  return sasToken;
};

export default useFetchSasToken;
