import { useEffect, useRef } from 'react';

import * as Ably from 'ably';
import { AblyProvider } from 'ably/react';

import { ApiVersion, baseURL } from '@/config';
import { useAuthContext } from '@/hooks';

import { AppRoutes } from './routes';

function AblyWrapper() {
  const { accessToken } = useAuthContext();
  const ablyRef = useRef<Ably.Types.RealtimePromise | null>(null);

  useEffect(() => {
    if (accessToken) {
      if (ablyRef.current) {
        ablyRef.current.close();
      }

      ablyRef.current = new Ably.Realtime.Promise({
        authHeaders: {
          Authorization: `Bearer ${accessToken}`,
        },
        authUrl: `${baseURL}api/${ApiVersion.V1}/users/ably-token-request`,
        authMethod: 'POST',
        echoMessages: false,
      });
    }

    return () => {
      if (ablyRef.current) {
        ablyRef.current.close();
        ablyRef.current = null;
      }
    };
  }, [accessToken]);

  if (!accessToken) return <AppRoutes />;

  if (!ablyRef.current) return null;

  return (
    <AblyProvider client={ablyRef.current}>
      <AppRoutes />
    </AblyProvider>
  );
}

export default AblyWrapper;
