import { FunctionComponent, SVGAttributes } from 'react';

import {
  IcBell,
  IcCall,
  IcFolder,
  IcHelpCircle,
  IcNote,
  IcPerson,
  IcSettings,
  IcTask,
} from '@/assets/images';

export interface ISideBarItem {
  key: string;
  label?: string;
  icon?: FunctionComponent<SVGAttributes<SVGAElement>>;
  link: string;
  items?: ISideBarItem[];
  submenuEl?: HTMLElement;
  setSubmenuEl?: React.Dispatch<React.SetStateAction<HTMLElement | undefined>>;
}

export const SidebarItems1: ISideBarItem[] = [
  {
    key: 'agencies',
    label: 'Agencies',
    icon: IcPerson,
    link: '/agencies',
  },
  {
    key: 'cfs',
    label: 'Call For Service',
    icon: IcCall,
    link: '/cfs',
  },
  {
    key: 'cfs-active',
    label: 'Active Dispatch Requests',
    icon: IcBell,
    link: '/cfs-active',
  },
  {
    key: 'kases',
    label: 'Cases',
    icon: IcNote,
    link: '/cases',
  },
  {
    key: 'master-index',
    label: 'Master Index',
    icon: IcFolder,
    link: '/master-index',
  },
  {
    key: 'tasks',
    label: 'Tasks',
    icon: IcTask,
    link: '/tasks',
  },
];

export const SidebarItems2: ISideBarItem[] = [
  {
    key: 'help',
    label: 'Help',
    icon: IcHelpCircle,
    link: '/help',
  },
  {
    key: 'settings',
    label: 'Settings',
    icon: IcSettings,
    link: '/settings',
  },
];

export enum SwipeMotionDirection {
  Left = 'Left',
  Right = 'Right',
}

export const SwipeAnimates = {
  [SwipeMotionDirection.Left]: {
    initial: { x: '100%' },
    animate: { x: '0' },
  },
  [SwipeMotionDirection.Right]: {
    initial: { x: '-100%' },
    animate: { x: '0' },
  },
};
