import { IAddress } from '@/models';

export const getAddressLocation = (
  address?: IAddress,
  ignoreSubAddress?: boolean,
): string => {
  if (!address) {
    return '';
  }
  const {
    addressLabel,
    subAddress,
    intersectionStreets,
    zipName,
    state,
    zipcode,
  } = address;
  let addressLine = addressLabel ?? '';
  if (intersectionStreets && intersectionStreets.length) {
    addressLine = intersectionStreets.join(' & ');
  }
  if (!ignoreSubAddress && subAddress)
    addressLine = `${addressLine} ${subAddress}`;

  if (zipName) {
    addressLine = `${addressLine}, ${zipName}`;
  }

  return `${addressLine}, ${state} ${zipcode}`;
};

// String of same fields of Main address item and sub-address item.
export const getAddressCommonString = (address?: IAddress) => {
  if (!address) return '';
  return `${address.addressNumber}, ${address.completeStreetName}, ${address.zipcode}`;
};
