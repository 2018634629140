import { colors } from '../variables';

export default {
  MuiTabs: {
    styleOverrides: {
      root: {
        '.MuiTab-root': {
          textTransform: 'capitalize',
        },

        '.MuiButtonBase-root': {
          color: colors.grey[60],
          padding: 0,
          marginRight: '20px',
        },
        '.MuiButtonBase-root.Mui-selected': {
          color: colors.black,
        },
        '.MuiTabs-indicator': {
          backgroundColor: colors.error.main,
        },
      },
    },
  },
};
