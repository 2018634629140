import { useEffect, useState } from 'react';

import clsx from 'clsx';
import { v4 as uuidv4 } from 'uuid';

import useFetchSaasToken from '@/hooks/useFetchSaasToken';
import { IFile } from '@/models';
import { useFileStore } from '@/store';

import { FileListItem } from './FileListItem';
import { FileListRoot } from './styles';

export type FilePickerDirection = 'vertical' | 'horizontal';

export interface FileListProps {
  files?: IFile[];
  onRemove: (index: number) => void;
  itemClassName?: string;
  enableEditDescription?: boolean;
  direction?: FilePickerDirection;
}

export const FileList = (props: FileListProps) => {
  const {
    files = [],
    onRemove,
    itemClassName,
    enableEditDescription,
    direction = 'horizontal',
  } = props;

  const [filesWithIds, setFilesWithIds] = useState<(IFile & { id: string })[]>(
    [],
  );

  useEffect(() => {
    const updatedFiles = files.map((file) => ({
      ...file,
      id: uuidv4(),
    }));
    setFilesWithIds(updatedFiles);
  }, [files]);

  const { fetching } = useFileStore();

  const sasToken = useFetchSaasToken(files, fetching);

  return (
    <FileListRoot
      className={clsx('fileList', direction === 'vertical' && 'vertical')}
    >
      {filesWithIds.map((file, index) => (
        <FileListItem
          key={file.id}
          index={index}
          item={file}
          enableEditDescription={enableEditDescription}
          className={itemClassName}
          sasToken={sasToken}
          onRemove={() => onRemove(index)}
        />
      ))}
    </FileListRoot>
  );
};
