import { PropsWithChildren, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import { IconButton, Stack, StackProps, Typography } from '@mui/material';

import { IcArrowLeft } from '@/assets/images';

import { PageHeaderRoot } from './styles';

interface PageHeaderProps extends PropsWithChildren, Omit<StackProps, 'title'> {
  backRoute?: string;
  title?: ReactNode | string;
  onBack?: () => void;
}

export const PageHeader = (props: PageHeaderProps) => {
  const { backRoute, title, children, onBack, ...rest } = props;

  const navigate = useNavigate();

  const handleBack = () => {
    if (onBack) {
      onBack();
    } else if (backRoute) {
      navigate(backRoute);
    } else {
      navigate(-1); // This uses browser history to go back
    }
  };

  return (
    <PageHeaderRoot {...rest}>
      <Stack className="header-breadcrumb">
        <IconButton className="btnBack" onClick={handleBack}>
          <IcArrowLeft />
        </IconButton>
        {!!title && <Typography className="page-title">{title}</Typography>}
      </Stack>
      <Stack
        flex={1}
        flexDirection="row"
        justifyContent="flex-end"
        alignItems="center"
        flexWrap="wrap"
        columnGap={2}
      >
        {children}
      </Stack>
    </PageHeaderRoot>
  );
};