import { Ref, forwardRef } from 'react';

import { FormHelperText, InputLabel, styled } from '@mui/material';
import {
  DatePickerProps,
  LocalizationProvider,
  DatePicker as MuiDatePicker,
} from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';

import { BaseFieldProps } from '../types';

export const DatePickerRoot = styled('div')(() => ({
  flex: 1,
  minWidth: '100%',
  maxWidth: '100%',
  marginBottom: 8,
  '& .MuiFormControl-root': {
    width: '100%',
  },
}));

export interface BaseDatePickerProps
  extends DatePickerProps<string>,
    BaseFieldProps {}

export const DatePicker = forwardRef<Ref<any>, BaseDatePickerProps>(
  (props, ref) => {
    const { label, error, ...rest } = props;

    return (
      <DatePickerRoot>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          {label && <InputLabel>{label}</InputLabel>}
          <MuiDatePicker
            {...rest}
            inputRef={ref as Ref<HTMLInputElement>}
            sx={{ mt: 0 }}
          />
          <FormHelperText error>{error}</FormHelperText>
        </LocalizationProvider>
      </DatePickerRoot>
    );
  },
);

DatePicker.displayName = 'Custom Date Picker';
