import { PaletteMode } from '@mui/material';

import { colors } from './variables';

export const getDesignTokens = (mode: PaletteMode) => ({
  mode,
  ...(mode === 'light'
    ? {
        ...colors,
        background: {
          paper: '#FFFFFF',
          default: '#F5F7F7',
        },
        text: {
          primary: colors.grey[100],
          secondary: colors.grey[80],
        },
      }
    : {
        ...colors,
        background: {
          paper: '#0E3145',
          default: '#264658',
        },
        text: {
          primary: colors.white,
          secondary: colors.grey[40],
        },
      }),
});
