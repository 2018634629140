import { Stack, Typography } from '@mui/material';

import { colors } from '@/theme/variables';
import { metersToMilesAndFeet } from '@/utils';

interface UnitItemDetailProps {
  cfsNumber?: string;
  sectorNames: string;
  addressLabel: string;
  distance?: number;
}

export const UnitItemDetail = (props: UnitItemDetailProps) => {
  const { cfsNumber, sectorNames, addressLabel, distance } = props;

  return (
    <Stack className="content-section" alignItems="center">
      <Stack flex={1}>
        {!!cfsNumber && <Typography variant="button">{cfsNumber}</Typography>}
        <Stack
          direction="row"
          divider={<div className="txt-direction">&bull;</div>}
          spacing={0.5}
        >
          <Typography className="txt-location" variant="body2">
            {addressLabel}
          </Typography>
          {distance !== undefined && (
            <Typography className="txt-direction" variant="body2">
              ({metersToMilesAndFeet(distance)})
            </Typography>
          )}
        </Stack>
        <Typography
          variant="body2"
          color={colors.grey[80]}
          className="txt-sector"
        >
          {sectorNames}
        </Typography>
      </Stack>
    </Stack>
  );
};
