export const parsePhoneNumber = (phoneNumber?: string, countryCode = '+1') => {
  if (!phoneNumber) return '';
  if (phoneNumber.includes(countryCode)) {
    return phoneNumber.replace(countryCode, '');
  }
  return countryCode + phoneNumber;
};

export const formatPhoneNumber = (str?: string) => {
  if (!str) return '';
  const match = str.match(/(\d{3})(\d{3})(\d{4})$/);

  return match ? `(${match[1]}) ${match[2]}-${match[3]}` : str;
};

export const convertToAsteriskSSN = (lastDigitsString: string) => {
  return lastDigitsString ? `*****${lastDigitsString}` : '';
};
