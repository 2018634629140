import { ComponentPropsWithoutRef, Fragment } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Badge, Button, Stack, styled } from '@mui/material';

import { IcAlertTriangle } from '@/assets/images';
import { colors, sizes } from '@/theme/variables';

import { useTabLayoutContext } from '../TabLayoutContext';

const RootStack = styled(Stack)(() => ({
  backgroundColor: colors.white,
  borderRadius: '8px',
  padding: `${sizes.bottomTabBar.padding}px`,
  boxShadow: `0px 0px 0px 1px ${colors.grey[10]}`,
  bottom: `${sizes.bottomTabBar.margin}px`,
  zIndex: '1',
  '& .tab_button': {
    textDecoration: 'none',
    padding: '5px 25px',
    fontWeight: 500,
    fontSize: 15,
    color: 'inherit',
    '&.active': {
      color: colors.error.main,
    },
  },
  '&.topBar': {
    alignItems: 'center',
    borderRadius: 0,
    background: 'none',
    boxShadow: 'none',
    borderBottom: `1px solid ${colors.grey[20]}`,
    padding: 0,
    height: 50,
    '& .tab_button': {
      padding: '5px 0',
      marginRight: '20px',
      color: colors.grey[60],
      '&.active': {
        color: colors.black,
        '&:after': {
          content: '""',
          position: 'absolute',
          width: '100%',
          background: colors.error.main,
          height: 2,
          bottom: -11,
        },
      },
    },
  },
}));
interface BottomTabBarProps extends ComponentPropsWithoutRef<typeof Stack> {
  additionalInfo?: Array<{
    count?: number;
    showWarning?: boolean;
  }>;
  isQuickView?: boolean;
  cfsId?: string; // Add cfsId prop
}

export const BottomTabBar = ({
  additionalInfo,
  isQuickView,
  cfsId,
  children,
  ...rest
}: BottomTabBarProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { tabLinks, currentNavTab, updateTabLayoutContextValues } =
    useTabLayoutContext();

  const onClick = (path: string) => {
    if (isQuickView && cfsId) {
      // Handle navigation logic for quick view (modal)
      navigate(`/cfs-active/${cfsId}/${path}`);
    } else {
      if (
        path === currentNavTab?.path &&
        !location.pathname.includes('cases')
      ) {
        navigate(location.pathname.replace(path, ''));
        return;
      }
      updateTabLayoutContextValues({
        nextPath: location.pathname,
        currentScreen: undefined,
      });
      navigate(path);
    }
  };

  return (
    <RootStack flexDirection="row" {...rest}>
      {tabLinks.map((link, index) => {
        const { showWarning = undefined, count = undefined } =
          additionalInfo?.[index] || {};

        return (
          <Fragment key={link.path}>
            {showWarning && (
              <IcAlertTriangle
                style={{ alignSelf: 'center', marginRight: -20 }}
              />
            )}
            <Button
              className={`tab_button ${
                currentNavTab?.path === link.path && 'active'
              }`}
              variant="text"
              onClick={() => onClick(link.path)}
            >
              {link.label}
              {!!count && <Badge badgeContent={count} sx={{ ml: 2 }} />}
            </Button>
          </Fragment>
        );
      })}
      {children}
    </RootStack>
  );
};
