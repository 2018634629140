import { FC, useEffect, useState } from 'react';

import { SxProps, styled } from '@mui/material/styles';

import { IcClose, IcSearch } from '@/assets/images';
import { colors } from '@/theme/variables';

import { Input, InputProps } from '../FormElements/Input';

const Root = styled('div')(({ theme, style }) => ({
  height: 36,
  width: 300,
  maxWidth: style?.maxWidth ? style.maxWidth : 350,
  backgroundColor: theme.palette.background.paper,
  position: 'relative',
  marginRight: 10,
  borderRadius: 6,

  '& .MuiInputBase-root': {
    width: '100%',
    padding: '6px 10px',
    color: colors.grey[80],
    ...(theme.palette.mode !== 'light' && {
      borderColor: colors.grey[60],
      color: colors.grey[20],
    }),
  },

  ...(theme.palette.mode !== 'light' && {
    backgroundColor: colors.grey[80],
  }),
}));

interface SearchBarProps extends Omit<InputProps, 'onChange'> {
  className?: string;
  onChange: (value: string) => void;
  cancelCallback?: () => void;
  sx?: SxProps;
  shouldCancel?: string;
}

export const SearchBar: FC<SearchBarProps> = ({
  name,
  label,
  placeholder,
  disabled,
  onChange,
  className,
  fullWidth,
  cancelCallback,
  shouldCancel,
  sx,
}) => {
  const [value, setValue] = useState('');
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    onChange(e.target.value);
  };

  // Reset search bar value when shouldCancel changes
  useEffect(() => {
    handleCancel();
  }, [shouldCancel]);

  const handleCancel = () => {
    onChange('');
    setValue('');
    if (typeof cancelCallback === 'function') {
      cancelCallback();
    }
  };

  const handleKeyUp = (e: any) => {
    if (e.keyCode === 27 || e.code === 'Escape') {
      handleCancel();
    }
  };

  return (
    <Root className={className ?? ''} sx={sx}>
      <Input
        name={name}
        label={label}
        value={value}
        placeholder={placeholder ?? 'Search'}
        onChange={handleChange}
        onKeyUp={handleKeyUp}
        disabled={disabled}
        startAdornment={<IcSearch style={{ marginRight: 4 }} />}
        endAdornment={
          <IcClose
            onClick={handleCancel}
            style={{
              display: value.length > 0 ? 'block' : 'none',
              cursor: 'pointer',
            }}
          />
        }
        fullWidth={fullWidth}
      />
    </Root>
  );
};
