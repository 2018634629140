import { LocalStoragePrefix } from '@/constants';
import { ICFSLog, IKaseLog, IPenalCode } from '@/models';

interface LocalStorageGetParams {
  prefix?: LocalStoragePrefix;
  key: string;
  shouldParse?: boolean;
}

interface LocalStorageSetParams {
  prefix?: LocalStoragePrefix;
  key: string;
  value: any;
}

export const getLocalStorageKey = (
  key: string,
  prefix?: LocalStoragePrefix,
) => {
  if (!prefix) return key;
  return `${prefix}-${key}`;
};

export const setLocalStorageItem = ({
  prefix,
  key,
  value,
}: LocalStorageSetParams) => {
  const storageKey = getLocalStorageKey(key, prefix);
  if (typeof value === 'object') {
    localStorage.setItem(storageKey, JSON.stringify(value));
  } else {
    localStorage.setItem(storageKey, value);
  }
};

export const getLocalStorageItem = ({
  prefix,
  key,
  shouldParse,
}: LocalStorageGetParams) => {
  const storageKey = getLocalStorageKey(key, prefix);
  const item = localStorage.getItem(storageKey);
  return shouldParse && item ? JSON.parse(item || '') : item;
};

export const getPenalCodeLabel = (penalCode: IPenalCode) => {
  const firstPart = [
    penalCode.lawTitle,
    penalCode.section,
    penalCode.subsection,
    penalCode.statutoryClass,
    penalCode.category,
    penalCode.degree,
  ]
    .filter((item) => !!item)
    .join(' ');
  return [firstPart, penalCode.miniLawDescription]
    .filter((item) => !!item)
    .join(' - ');
};

export const createUrlWithParams = (
  _baseURL: string,
  params: Record<string, any>,
) => {
  const url = new URL(_baseURL);
  Object.keys(params).forEach((key) =>
    url.searchParams.append(key, params[key]),
  );
  return url.toString();
};

export const filterLogs = <T extends IKaseLog | ICFSLog>(
  logs: T[],
  searchTerm: string,
): T[] => {
  if (!searchTerm) return logs;

  const lowercasedTerm = searchTerm.toLowerCase();

  return logs.filter((log) => {
    const matchesEvent = log.event.toLowerCase().includes(lowercasedTerm);
    const matchesCreatedBy =
      log.createdBy?.firstName.toLowerCase().includes(lowercasedTerm) ||
      log.createdBy?.lastName.toLowerCase().includes(lowercasedTerm);
    const matchesData =
      log.data &&
      JSON.stringify(log.data?.new || '')
        .toLowerCase()
        .includes(lowercasedTerm);

    return matchesEvent || matchesCreatedBy || matchesData;
  });
};

export const getKeyByValue = (
  mapName: Map<any, any>,
  searchValue: any,
): any | undefined => {
  for (const [key, value] of mapName.entries()) {
    if (value === searchValue) {
      return key;
    }
  }
  return undefined;
};

export const preventSubmit = (event: {
  key: string;
  preventDefault: () => void;
  stopPropagation: () => void;
}) => {
  if (event?.key === 'Enter') {
    event.preventDefault();
    event.stopPropagation();
    return false;
  }
};

export const getKeysByValues = (
  values: string[],
  type: { [key: string]: string },
) => {
  const keys = values.map((value: string) => {
    const entry = Object.entries(type).find(([, val]) => val === value);
    return entry ? entry[0] : null;
  });

  return keys;
};

export const handlePrint = (selector: string) => {
  const printContents = document.querySelector(selector)?.innerHTML;

  if (printContents) {
    window.onafterprint = () => {
      window.location.reload();
    };
    document.body.innerHTML = printContents;
    window.print();
  }

  window.onafterprint = () => {
    window.location.reload();
  };
};
