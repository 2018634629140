import { FC, useEffect, useRef } from 'react';

import { Box, Divider, Stack, Typography } from '@mui/material';

import { IAdditionalInfo } from '@/models';

import { AdditionalInfoInput } from './AdditionalInfoInput';
import { AdditionalInfoItem } from './AdditionalInfoItem';
import { AdditionalInfoList } from './styles';

interface IAdditionalInfoProps {
  additionalInfoList?: IAdditionalInfo[];
  onSubmit: (value: string) => void;
  classes?: { additionalInfoList: string };
}

export const AdditionalInfoForm: FC<IAdditionalInfoProps> = ({
  additionalInfoList = [],
  onSubmit,
  classes,
}) => {
  const infoListBottomRef = useRef<HTMLDivElement>(null);

  // Display the last message into the view port as default
  useEffect(() => {
    infoListBottomRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
  }, [additionalInfoList.length]);

  return (
    <Stack rowGap={2} flex={1}>
      <AdditionalInfoList className={classes?.additionalInfoList}>
        {additionalInfoList.length ? (
          <Box className="content">
            {additionalInfoList.map((item, index) => (
              <AdditionalInfoItem
                key={`${item.createdBy?._id}-${index}`}
                additionalInfo={item}
              />
            ))}
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            <Typography variant="body2">No message to display</Typography>
          </Box>
        )}
        <Box ref={infoListBottomRef} />
      </AdditionalInfoList>

      <Divider />

      <Box sx={{ flex: 'none' }}>
        <AdditionalInfoInput onSubmit={onSubmit} />
      </Box>
    </Stack>
  );
};
