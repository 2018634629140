import { Suspense } from 'react';

import { CssBaseline, ThemeProvider } from '@mui/material';
import { LicenseInfo } from '@mui/x-date-pickers-pro';
import * as Sentry from '@sentry/react';

import { muiLicenseKey } from '@/config';

import AblyWrapper from './AblyWrapper';
import { Toast } from './components/Toast';
import { AuthProvider } from './contexts';
import { createCustomTheme } from './theme';

const theme = createCustomTheme('light');
LicenseInfo.setLicenseKey(muiLicenseKey);

function App() {
  return (
    <Sentry.ErrorBoundary>
      <Suspense>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Toast />
          <AuthProvider>
            <AblyWrapper />
          </AuthProvider>
        </ThemeProvider>
      </Suspense>
    </Sentry.ErrorBoundary>
  );
}

export default Sentry.withProfiler(App);
