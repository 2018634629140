export enum AuthRoutes {
  Login = '/auth/login',
  ForgotPassword = '/auth/forgot-password',
  ResetPassword = '/auth/reset-password',
  ConfirmEmail = '/auth/confirm-email',
  CodeVerification = '/auth/code-verification',
}

export enum MainRoutes {
  Home = '/',
  AgencyList = '/agencies',
  EmployeeList = ':agencyId/employees',
  VehicleList = ':agencyId/vehicles',
  EquipmentList = ':agencyId/equipment',
  InventoryLocations = ':agencyId/inventory-locations',
  UnitList = ':agencyId/units',
  CFSList = '/cfs',
  ActiveCFS = '/cfs-active',
  KaseList = '/cases',
  TaskList = '/tasks',
  VendorList = ':agencyId/vendors',
}
