import { FC } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { Divider, Tab } from '@mui/material';

import { Page, PageHeader } from '@/components';
import { Tabs } from '@/components/Tabs';

import { MasterIndexTabs } from './data';

export const MasterIndex: FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const paths = pathname.split('/');
  const showTabs = paths.length < 5 && paths[2];

  return (
    <Page title="Master Index" style={!showTabs ? { paddingTop: 10 } : {}}>
      <PageHeader title="Master Index" />
      {showTabs && (
        <>
          <Tabs
            value={paths[2]}
            onChange={(_e, newValue: string) => navigate(newValue)}
          >
            {MasterIndexTabs.map((tab) => (
              <Tab key={tab} label={tab} value={tab} />
            ))}
          </Tabs>
          <Divider />
        </>
      )}
      <Outlet />
    </Page>
  );
};
