import { FC, useEffect, useLayoutEffect, useState } from 'react';

import {
  Box,
  Chip,
  Divider,
  IconButton,
  Stack,
  StackProps,
  Tab,
  Tabs,
} from '@mui/material';

import { CfsApi, MapLayerApi } from '@/api';
import { IcRefresh } from '@/assets/images';
import { EditableRequiredUnits, SearchBar } from '@/components';
import { LocalStorageItems } from '@/constants';
import { WithLoaderProps } from '@/hocs';
import { useCFSContext } from '@/hooks';
import { PolygonLayerType } from '@/models';
import { ConfirmTypes, useDataStore, useToastStore } from '@/store';

import AssignedUnits from './AssignedUnits';
import AvailableUnits from './AvailableUnits';
import OffDutyUnits from './OffDutyUnits';
import { TabPanel } from './TabPanel';
import { UnitFilter } from './UnitFilter';
import { FilterValues } from './UnitFilterForm';
import { initFilterValues } from '../data';
import { UnitShiftListRoot } from '../styles';

interface UnitShiftListProps extends StackProps {
  cfsId?: string;
  fetchActiveCFSList?: () => void;
}

export interface CommonUnitsProps extends WithLoaderProps {
  cfsId?: string;
  filterValues: FilterValues;
  searchText?: string;
  handleAssignUnit?: () => void;
}

export const UnitShiftList: FC<UnitShiftListProps> = ({
  cfsId,
  fetchActiveCFSList,
  ...rest
}) => {
  const { cfs, handleRefresh } = useCFSContext();
  const { updateData } = useDataStore();
  const { updateToast } = useToastStore();
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [shouldCancel, setShouldCancel] = useState<string>('');
  const [filterValues, setFilterValues] =
    useState<FilterValues>(initFilterValues);

  const [searchUnitText, setSearchUnitText] = useState<string>('');

  const cfsBeatName = cfs?.addressInfo?.additionalInfo?.beat?.name;
  useLayoutEffect(() => {
    if (cfsId) localStorage.setItem(LocalStorageItems.CurrentAgencyId, '');
  }, [cfsId]);
  useEffect(() => {
    fetchBeatPolygonLayers();
  }, []);

  const fetchBeatPolygonLayers = () => {
    const beatParam = { type: PolygonLayerType.BEAT };
    MapLayerApi.getPolygonLayers({
      filter: JSON.stringify(beatParam),
      limit: 1000,
    })
      .then(({ data: { results } }) => {
        updateData({ beats: results });
      })
      .catch((err: any) => {
        updateToast({ open: true, message: err.message });
      });
  };

  const onFilter = (data: FilterValues) => {
    setFilterValues(data);
  };

  const handleRefreshUnitShiftDistances = () => {
    CfsApi.refreshUnitShiftDistances(cfsId as string)
      .then(() => {
        updateToast({
          open: true,
          message: 'Refreshed successfully',
          type: ConfirmTypes.SUCCESS,
        });
      })
      .catch((err: any) => {
        updateToast({
          open: true,
          message: err.message,
          type: ConfirmTypes.WARNING,
        });
      });
    handleRefresh();
  };

  const handleAssignUnit = () => {
    // clear the search text and trigger the useEffect in SearchBar after assigning a unit
    setSearchUnitText('');
    // change the value of shouldCancel to trigger the useEffect in SearchBar
    setShouldCancel(Date.now().toString());
  };

  return (
    <UnitShiftListRoot {...rest} className={cfsId && 'hasCfsId'}>
      {!!cfsId && (
        <Stack className="header">
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            // flex={1}
          >
            {cfsBeatName && (
              <Chip label={cfsBeatName} className="sector-chip" />
            )}
            <div
              style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}
            >
              <EditableRequiredUnits />
            </div>
          </Stack>
          <Divider sx={{ mt: 2 }} />
        </Stack>
      )}

      <Box className="tab-box">
        <Tabs value={currentTab} onChange={(_, value) => setCurrentTab(value)}>
          <Tab value={0} label="Available" />
          <Tab value={1} label="Assigned" />
          {!cfsId && <Tab value={2} label="Off-duty" />}
        </Tabs>
        {!!cfsId && (
          <IconButton onClick={handleRefreshUnitShiftDistances}>
            <IcRefresh />
          </IconButton>
        )}
      </Box>
      <Divider style={{ margin: '0px 8px' }} />
      <Box className="tab-box">
        <Stack
          sx={{ gap: '15px', width: '100%' }}
          flexDirection="row"
          margin="8px 0px"
        >
          <SearchBar
            onChange={setSearchUnitText}
            shouldCancel={shouldCancel}
            sx={{
              marginRight: '0',
              flex: 1,
              maxWidth: '100%',
            }}
            placeholder="Search..."
          />

          <UnitFilter onFilter={onFilter} />
        </Stack>
      </Box>
      <TabPanel value={0} index={currentTab}>
        <AvailableUnits
          cfsId={cfsId}
          filterValues={filterValues}
          searchText={searchUnitText}
          setCurrentTab={setCurrentTab}
          fetchActiveCFSList={fetchActiveCFSList}
          handleAssignUnit={handleAssignUnit}
        />
      </TabPanel>
      <TabPanel value={1} index={currentTab}>
        <AssignedUnits
          cfsId={cfsId}
          filterValues={filterValues}
          searchText={searchUnitText}
          handleAssignUnit={handleAssignUnit}
        />
      </TabPanel>
      <TabPanel value={2} index={currentTab}>
        <OffDutyUnits filterValues={filterValues} searchText={searchUnitText} />
      </TabPanel>
    </UnitShiftListRoot>
  );
};
