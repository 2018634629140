import { useMemo, useState } from 'react';

import { Typography } from '@mui/material';
import clsx from 'clsx';

import {
  IcClose,
  IcFileX,
  IcImage,
  IcNote,
  IcVideoRecorder,
} from '@/assets/images';
import { IFile } from '@/models';
import { fileSizeConverter, getFileType } from '@/utils';

import { FileListItemRoot } from './styles';
import { FilePreviewModal, FormInputField } from '..';

interface FileListItemProps {
  sasToken?: string;
  className?: string;
  item: IFile;
  enableEditDescription?: boolean;
  onRemove: () => void;
  index: number;
}

export const FileListItem = (props: FileListItemProps) => {
  const { className, onRemove, item, sasToken, enableEditDescription, index } =
    props;
  const { name, size, error, mimeType } = item;

  const [fileViewModal, setFileViewModal] = useState<{
    open: boolean;
    file?: IFile;
  }>({
    open: false,
  });

  const isPreviewUnavailable = useMemo(() => {
    return !item || !sasToken;
  }, [item, sasToken]);

  const renderFileIcon = () => {
    if (error) {
      return <IcFileX />;
    }
    const fileType = getFileType(mimeType);

    if (fileType === 'image') return <IcImage />;
    if (fileType === 'video') return <IcVideoRecorder />;
    return <IcNote />;
  };
  const handleModalOpen = () => {
    if (isPreviewUnavailable) {
      return;
    }
    setFileViewModal({ open: true, file: item });
  };

  return (
    <FileListItemRoot className={className}>
      <div
        className="icon_box"
        onClick={handleModalOpen}
        style={{ cursor: isPreviewUnavailable ? 'not-allowed' : 'pointer' }}
      >
        {renderFileIcon()}
      </div>
      <div
        className={clsx('content_box', {
          'edit-description': enableEditDescription,
        })}
      >
        <div>
          <Typography className="txt_name">{name}</Typography>
          {!error ? (
            <Typography className="txt_size">
              {fileSizeConverter(size)}
            </Typography>
          ) : (
            <Typography className="txt_error">{error}</Typography>
          )}
        </div>
        {enableEditDescription && (
          <FormInputField
            defaultValue={item.description || ''}
            style={{ marginTop: '8px' }}
            name={`files.${index}.description`}
            placeholder="Description"
            rules={{
              maxLength: {
                value: 100,
                message: 'Description must be 100 characters or less.',
              },
            }}
          />
        )}
        <IcClose
          className="icon_remove"
          onClick={onRemove}
          style={{ marginLeft: enableEditDescription ? '15px' : '' }}
        />
      </div>
      <FilePreviewModal
        open={fileViewModal.open}
        file={fileViewModal.file}
        onClose={() => {
          setFileViewModal({ open: false });
        }}
      />
    </FileListItemRoot>
  );
};
