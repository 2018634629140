import { FC, useEffect, useState } from 'react';

import { Button, Stack } from '@mui/material';
import { useChannel } from 'ably/react';

import { CfsApi, UnitApi } from '@/api';
import { ActionButton } from '@/components';
import { withLoader } from '@/hocs';
import { useCFSContext, useUnitListFilter, useUnitShiftContext } from '@/hooks';
import { IUnit, IUnitShift } from '@/models';
import { getUnitShiftAddressLabel } from '@/services';
import { ConfirmTypes, useToastStore, useUserStore } from '@/store';

import { UnitAccordion } from './UnitAccordion';
import { UnitItem } from './UnitItem';
import {
  default as UnitShiftAssignModal,
  UnitShiftAssignModalProps,
} from './UnitShiftAssignModal';
import { CommonUnitsProps } from './UnitShiftList';
import { availableHQUnitActions, availableUnitActions } from '../data';

interface AvailableUnitsProps extends CommonUnitsProps {
  setCurrentTab: (tabNumber: number) => void;
  fetchActiveCFSList?: () => void;
  handleAssignUnit?: () => void;
}
const AvailableUnits: FC<AvailableUnitsProps> = ({
  cfsId,
  filterValues,
  searchText,
  showLoader,
  hideLoader,
  setCurrentTab,
  fetchActiveCFSList,
  handleAssignUnit,
}) => {
  const { updateToast } = useToastStore();
  const { cfs, unitShiftDistances, fetchCFS } = useCFSContext();
  const {
    onDutyUnitShifts,
    waitingUnitShifts,
    unitShiftAddresses,
    fetchOnDutyUnitShifts,
    fetchWaitingUnitShifts,
  } = useUnitShiftContext();
  const { account } = useUserStore();
  const [expanded, setExpanded] = useState<string | null>(null);
  const [standByUnits, setStandByUnits] = useState<IUnit[]>([]);
  const [assignUnitShiftModal, setAssignUnitShiftModal] = useState<
    Omit<UnitShiftAssignModalProps, 'onClose' | 'showLoader' | 'hideLoader'>
  >({
    open: false,
  });

  useChannel(`account:${account?._id}:unit`, () => {
    fetchStandByUnits();
  });

  const activeUnitShiftData = useUnitListFilter({
    filterValues,
    cfs,
    unitData: onDutyUnitShifts,
    searchText,
    searchResources: true,
  }) as IUnitShift[];
  const standByUnitsData = useUnitListFilter({
    filterValues,
    cfs,
    unitData: standByUnits,
    searchText,
  }) as IUnit[];
  const waitingUnitShiftData = useUnitListFilter({
    filterValues,
    cfs,
    unitData: waitingUnitShifts,
    searchText,
  }) as IUnitShift[];

  useEffect(() => {
    Promise.all([
      fetchStandByUnits(),
      fetchOnDutyUnitShifts(),
      fetchWaitingUnitShifts(),
    ]).then(([standByUnitsRes, onDutyUnitShiftsRes, waitingUnitShiftsRes]) => {
      if (waitingUnitShiftsRes.length) {
        setExpanded('waiting');
      } else if (onDutyUnitShiftsRes.length) {
        setExpanded('on-duty');
      } else if (standByUnitsRes.length) {
        setExpanded('standby');
      }
    });
  }, []);

  const fetchStandByUnits = async () => {
    try {
      const filter = JSON.stringify({
        status: 'OFF_DUTY',
        isStandByWhenOffDuty: true,
      });
      const res = await UnitApi.getUnits({ filter, limit: 1000 });
      setStandByUnits(res.data.results);
      return res.data.results;
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
      return [];
    }
  };

  const handleExpand = (panel: string) => {
    if (expanded === panel) setExpanded(null);
    else setExpanded(panel);
  };

  const updateToOffDuty = async (unitId: string) => {
    try {
      await UnitApi.setUnitOffDuty(unitId);
      updateToast({
        type: ConfirmTypes.SUCCESS,
        open: true,
        message: 'Unit shift is now off-duty',
      });
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    }
  };

  const handleSelectAction = (
    action: string | number,
    unitShiftId?: string,
    unitId?: string,
  ) => {
    if (action === 'assign') {
      setAssignUnitShiftModal({
        open: true,
        unitShiftId,
      });
    } else if (action === 'assignToHQ') {
      setAssignUnitShiftModal({
        open: true,
        unitShiftId,
        assignToHQ: true,
      });
    } else {
      updateToOffDuty(unitId || '');
    }
  };

  const onCloseModal = () => {
    setAssignUnitShiftModal({
      open: false,
    });
  };

  const onOpenModal = (unit?: IUnit, assignToHQ?: boolean) => {
    setAssignUnitShiftModal({
      open: true,
      unitShiftId: undefined,
      unit,
      assignToHQ,
    });
  };

  const assignUnitShift = async (
    unitShiftId = '',
    isDispatchedToHQ?: boolean,
  ) => {
    showLoader();
    try {
      if (cfsId) {
        await CfsApi.assignUnitShift(cfsId, unitShiftId, isDispatchedToHQ);
        const response = await fetchCFS();

        if (response?.assignmentStatus === 'ASSIGNED') {
          setCurrentTab(1);
        }

        if (fetchActiveCFSList) {
          fetchActiveCFSList();
        }

        updateToast({
          type: ConfirmTypes.SUCCESS,
          open: true,
          message: 'Unit shift successfully assigned',
        });
        handleAssignUnit?.();
      }
    } catch (err: any) {
      updateToast({
        open: true,
        message: err.message,
        type: ConfirmTypes.WARNING,
      });
    } finally {
      hideLoader();
    }
  };

  return (
    <Stack flex={1}>
      {!!waitingUnitShiftData.length && (
        <UnitAccordion
          title="Waiting"
          value="waiting"
          badge={waitingUnitShiftData.length}
          currentValue={expanded}
          handleExpand={handleExpand}
        >
          {waitingUnitShiftData.map((unitShift, index) => (
            <UnitItem
              key={index}
              {...unitShift.unit}
              addressLabel={getUnitShiftAddressLabel(
                unitShift,
                unitShiftAddresses,
              )}
              distance={unitShiftDistances[unitShift?._id as string]}
              resources={{
                polygons: unitShift.polygons,
              }}
            >
              {cfsId ? (
                <>
                  {unitShift.unit.type.isDispatchToHQEnabled && (
                    <Button
                      variant="outlined"
                      color="inherit"
                      size="large"
                      onClick={() => assignUnitShift(unitShift._id, true)}
                      disabled={cfs?.isClosed}
                      sx={{ mr: 2, padding: '8px 20px' }}
                    >
                      Assign to HQ
                    </Button>
                  )}
                  <Button
                    color="error"
                    variant="contained"
                    size="large"
                    onClick={() => assignUnitShift(unitShift._id)}
                    disabled={cfs?.isClosed}
                  >
                    Assign
                  </Button>
                </>
              ) : (
                <ActionButton
                  color="error"
                  variant="contained"
                  size="large"
                  items={
                    unitShift.unit.type.isDispatchToHQEnabled
                      ? availableHQUnitActions
                      : availableUnitActions
                  }
                  onSelectItem={(action) =>
                    handleSelectAction(
                      action,
                      unitShift._id,
                      unitShift.unit._id,
                    )
                  }
                >
                  More actions
                </ActionButton>
              )}
            </UnitItem>
          ))}
        </UnitAccordion>
      )}
      {!!activeUnitShiftData.length && (
        <UnitAccordion
          title="On duty"
          value="on-duty"
          badge={activeUnitShiftData.length}
          currentValue={expanded}
          handleExpand={handleExpand}
        >
          {activeUnitShiftData.map((unitShift, index) => (
            <UnitItem
              key={index}
              {...unitShift.unit}
              addressLabel={getUnitShiftAddressLabel(
                unitShift,
                unitShiftAddresses,
              )}
              distance={unitShiftDistances[unitShift?._id as string]}
              resources={{
                polygons: unitShift.polygons,
                users: unitShift.users,
                equipments: unitShift.equipments,
                vehicles: unitShift.vehicles,
              }}
              itemType="on-duty"
              showResources
            >
              {cfsId ? (
                <>
                  {unitShift.unit.type.isDispatchToHQEnabled && (
                    <Button
                      variant="outlined"
                      color="inherit"
                      size="large"
                      onClick={() => assignUnitShift(unitShift._id, true)}
                      disabled={cfs?.isClosed}
                      sx={{ mr: 2, padding: '8px 20px' }}
                    >
                      Assign to HQ
                    </Button>
                  )}
                  <Button
                    color="error"
                    variant="contained"
                    size="large"
                    onClick={() => assignUnitShift(unitShift._id)}
                    disabled={cfs?.isClosed}
                  >
                    Assign
                  </Button>
                </>
              ) : (
                <ActionButton
                  color="error"
                  variant="contained"
                  size="large"
                  items={
                    unitShift.unit.type.isDispatchToHQEnabled
                      ? availableHQUnitActions
                      : availableUnitActions
                  }
                  onSelectItem={(action) =>
                    handleSelectAction(
                      action,
                      unitShift._id,
                      unitShift.unit._id,
                    )
                  }
                >
                  More actions
                </ActionButton>
              )}
            </UnitItem>
          ))}
        </UnitAccordion>
      )}
      {!!standByUnitsData.length && (
        <UnitAccordion
          title="Standby"
          value="standby"
          badge={standByUnitsData.length}
          currentValue={expanded}
          handleExpand={handleExpand}
        >
          {standByUnitsData.map((unit, index) => (
            <UnitItem key={index} {...unit}>
              {unit.type.isDispatchToHQEnabled && (
                <Button
                  variant="outlined"
                  color="inherit"
                  size="large"
                  onClick={() => onOpenModal(unit, true)}
                  sx={{ mr: 2, padding: '8px 20px' }}
                >
                  Assign to HQ
                </Button>
              )}
              <Button
                color="error"
                variant="contained"
                size="large"
                onClick={() => onOpenModal(unit)}
              >
                Assign
              </Button>
            </UnitItem>
          ))}
        </UnitAccordion>
      )}
      <UnitShiftAssignModal
        onClose={onCloseModal}
        cfsId={cfsId}
        {...assignUnitShiftModal}
      />
    </Stack>
  );
};

export default withLoader(AvailableUnits);
