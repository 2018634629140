export default {
  white: '#FFFFFF',
  black: '#000000',
  bgActive: '#0D2C3E',
  lightGrey: '#FAFBFB',
  itemGrey: '#E7EAEC66',
  primary: {
    light: '#CCDAFF',
    main: '#5586FF',
  },
  error: {
    light: '#F9C9D1',
    main: '#EE3E5C',
    dark: '#D63853',
  },
  secondary: {
    light: '#D3F0ED',
    main: '#6DCCC4',
  },
  warning: {
    light: '#FFEFB3',
    main: '#FECB00',
    dark: '#986316',
  },
  grey: {
    10: '#E7EAEC',
    20: '#CFD6DA',
    30: '#B7C1C7',
    40: '#9FADB5',
    50: '#8698A2',
    60: '#6E838F',
    70: '#566F7D',
    80: '#3E5A6A',
    90: '#264658',
    100: '#0E3145',
  },
};
