import { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  IconButton,
  Stack,
  StackProps,
  Typography,
} from '@mui/material';

import {
  IcArrowDown,
  IcArrowUp,
  IcCheckVerified,
  IcFire,
  IcMedicalCross,
  IcPolice,
} from '@/assets/images';
import { useCFSContext } from '@/hooks';
import {
  AgencyType,
  IUnitShiftResource,
  IUnitShiftResources,
  IUnitType,
} from '@/models';
import { getSectorNameInfo } from '@/services';
import { useDataStore } from '@/store';
import { colors } from '@/theme/variables';

import { UnitItemDetail } from './UnitItemDetail';
import { unitShiftViewItems } from '../data';
import { UnitItemRoot } from '../styles';

interface UnitItemProps extends PropsWithChildren {
  itemType?: string;
  name: string;
  type: IUnitType;
  cfsNumber?: string;
  isDispatchedToHQ?: boolean;
  hideDetail?: boolean;
  showResources?: boolean;
  resources?: IUnitShiftResources;
  rootProps?: StackProps;
  distance?: number;
  addressLabel?: string;
}

export const UnitItem: FC<UnitItemProps> = (props) => {
  const {
    itemType,
    name,
    type,
    cfsNumber,
    isDispatchedToHQ,
    resources,
    hideDetail,
    showResources,
    rootProps,
    distance,
    addressLabel,
  } = props;
  const { beats } = useDataStore();
  const { cfs } = useCFSContext();

  const [isExpanded, setIsExpanded] = useState(false);

  const polygons = resources?.polygons || [];
  const { isCoverCFS, sectorNames } = getSectorNameInfo(
    beats,
    cfs?.addressInfo?.address?.point,
    polygons,
    itemType === 'on-duty',
  );

  const getIcon = useMemo(() => {
    if (type?.agencyType === AgencyType.POLICE)
      return <IcPolice color={colors.grey[80]} style={{ marginTop: -1 }} />;
    if (type?.agencyType === AgencyType.EMS) return <IcMedicalCross />;
    return <IcFire color={colors.grey[80]} style={{ marginTop: -3 }} />;
  }, [type]);

  const getResourceItems = useCallback(() => {
    return unitShiftViewItems.reduce((total: any[], current) => {
      const currentResourceName = current.name as keyof typeof resources;
      if (
        resources &&
        resources[currentResourceName] &&
        (resources[currentResourceName] as IUnitShiftResource[]).length
      ) {
        const resourceItems = (
          resources[currentResourceName] as IUnitShiftResource[]
        ).map((resourceItem) => {
          const _resourceItem = resourceItem as IUnitShiftResource;
          return currentResourceName !== 'users'
            ? _resourceItem.name
            : `${_resourceItem.firstName} ${_resourceItem.lastName}`;
        });
        total.push({
          ...current,
          items: resourceItems,
        });
      }
      return total;
    }, []);
  }, [resources]);

  return (
    <UnitItemRoot {...rootProps}>
      <Stack className="content-section" alignItems="center">
        {isCoverCFS && <IcCheckVerified style={{ marginRight: 5 }} />}
        <Typography
          variant="button"
          color={colors.grey[100]}
          style={{ textTransform: 'none' }}
        >
          {name}
        </Typography>
        <Box className="name-box">
          {getIcon}
          <Typography variant="subtitle2" color={colors.grey[80]}>
            {type?.name}
          </Typography>
        </Box>
        {isDispatchedToHQ && (
          <Box className="name-box">
            <Typography variant="subtitle2">HQ</Typography>
          </Box>
        )}
        <div style={{ flex: 1 }} />
        {props.children}
      </Stack>
      {(!hideDetail || showResources) && <Divider sx={{ my: '15px' }} />}
      {!hideDetail && !showResources && (
        <UnitItemDetail
          cfsNumber={cfsNumber}
          sectorNames={sectorNames.join(', ')}
          addressLabel={addressLabel || ''}
          distance={distance}
        />
      )}
      {showResources && (
        <Accordion expanded={isExpanded}>
          <AccordionSummary onClick={() => setIsExpanded((v) => !v)}>
            <UnitItemDetail
              cfsNumber={cfsNumber}
              sectorNames={sectorNames.join(', ')}
              addressLabel={addressLabel || ''}
              distance={distance}
            />
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setIsExpanded((v) => !v);
              }}
              sx={{ flex: 0, padding: 0 }}
            >
              {isExpanded ? <IcArrowUp /> : <IcArrowDown />}
            </IconButton>
          </AccordionSummary>
          <AccordionDetails>
            <Divider sx={{ my: '15px' }} />
            <Stack flexDirection="row">
              {getResourceItems().map((resource) => (
                <Stack key={resource.name} className="resource-item">
                  <resource.icon style={{ marginRight: '16px' }} />
                  <Stack>
                    {resource.items.map(
                      (resourceItem: string, index: number) => (
                        <Typography key={index} className="resource-name">
                          {resourceItem}
                        </Typography>
                      ),
                    )}
                  </Stack>
                </Stack>
              ))}
            </Stack>
          </AccordionDetails>
        </Accordion>
      )}
    </UnitItemRoot>
  );
};
