import { AgencyType, IAgency, IUser, Role } from '@/models';

export const isUserRoleAgencyEqualsToAgencyId = (
  userRoleAgency: string | IAgency | undefined,
  agencyId: string,
) => {
  return (
    (typeof userRoleAgency === 'string' && userRoleAgency === agencyId) ||
    (typeof userRoleAgency !== 'string' && userRoleAgency?._id === agencyId)
  );
};

export const isAccountAdmin = (user: IUser) => {
  return isTargetRole(user, Role.ACCOUNT_ADMIN);
};

export const isAgencyAdmin = (user: IUser, agencyId?: string) => {
  return isTargetAgencyRole(user, Role.AGENCY_ADMIN, agencyId);
};

export const isAdmin = (user: IUser, agencyId: string) => {
  return isAccountAdmin(user) || isAgencyAdmin(user, agencyId);
};

export const isTargetRole = (user: IUser, targetRole: Role) => {
  return !!user.roles?.find(({ role }) => role === targetRole);
};

export const isTargetAgencyRole = (
  user: IUser,
  targetRole: Role,
  agencyId?: string,
) => {
  return !!user.roles?.find(
    ({ role, agency }) =>
      role === targetRole &&
      (!agencyId || isUserRoleAgencyEqualsToAgencyId(agency, agencyId)),
  );
};

// Check if user has another role except given roles
export const hasOtherRoleExcept = (user: IUser, roles: Role[]) => {
  return !!user.roles.find((userRole) => !roles.includes(userRole.role));
};

// Check if user has given role of given agency type
export const isAgencyTypeRole = (
  user: IUser,
  expectedRole: Role,
  expectedAgencyType: AgencyType,
) => {
  return !!user.roles.find((userRole) => {
    const agency = userRole.agency as IAgency | undefined;
    return (
      agency?.type === expectedAgencyType && expectedRole === userRole.role
    );
  });
};

export const hasAnyOfTargetRoles = (
  user: IUser,
  roles: Role[],
  agencyId?: string,
) => {
  let userRoles = user.roles;
  if (agencyId) {
    userRoles = userRoles.filter(({ agency }) =>
      isUserRoleAgencyEqualsToAgencyId(agency, agencyId),
    );
  }
  return userRoles.some(({ role }) => roles.indexOf(role) > -1);
};
