import { FC, useMemo } from 'react';

import { AssignedUnitShiftStatus, IAssignedUnitShift } from '@/models';

import { UnitItem } from './UnitItem';
import { ActionButton, ActionButtonItem } from '../../../Buttons';
import { unitShiftReassignActionItems, unitShiftStatusItems } from '../data';

interface AssignedUnitsProps extends IAssignedUnitShift {
  cfsNumber?: string;
  reassign?: boolean;
  unitShiftActionItems: ActionButtonItem[];
  addressLabel?: string;
  distance?: number;
  handleSelectAction: (value: string | number, reassign?: boolean) => void;
  isCfsClosed?: boolean;
}

export const AssignedUnitItem: FC<AssignedUnitsProps> = (props) => {
  const {
    status,
    unitShift,
    reassign,
    unitShiftActionItems,
    handleSelectAction,
    isCfsClosed,
  } = props;

  const assignedUnitShiftStatusItems = useMemo(() => {
    let items = unitShiftStatusItems.slice();
    if (status === AssignedUnitShiftStatus.QUEUED) {
      items = items.map((item) => ({ ...item, disabled: true }));
      items.push({
        label: 'Queued',
        value: AssignedUnitShiftStatus.QUEUED,
      });
    }
    return items;
  }, [status]);

  return (
    <UnitItem
      {...unitShift.unit}
      resources={{
        users: unitShift.users,
        equipments: unitShift.equipments,
        vehicles: unitShift.vehicles,
        polygons: unitShift.polygons,
      }}
      showResources
      {...props}
    >
      <>
        <ActionButton
          color="inherit"
          variant="contained"
          defaultValue={status}
          items={assignedUnitShiftStatusItems}
          onSelectItem={handleSelectAction}
          disabled={isCfsClosed}
        />
        <ActionButton
          color="error"
          variant="contained"
          disabled={
            (reassign && unitShift.unit.type.closeUnitShiftWithCFS) ||
            isCfsClosed
          }
          items={reassign ? unitShiftReassignActionItems : unitShiftActionItems}
          onSelectItem={(v) => handleSelectAction(v, reassign)}
        >
          {reassign ? 'Reassign to CFS' : 'More actions'}
        </ActionButton>
      </>
    </UnitItem>
  );
};
